var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      columns: _vm.unitExchangeColumns,
      "data-source": _vm.dataSource,
      pagination: _vm.pagination,
      paginationcustom: false,
      scroll: { x: "calc(2500px + 100%)", y: 600 },
      loading: _vm.loading
    },
    on: { change: _vm.handleTableChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }