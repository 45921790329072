












import { generateUUID } from "@/helpers/uuid";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IReportDailyOperationalExchange } from "./types";

type Row = {
  key: string;
  no: string;
  bastNo: string;
  documentNumber: string;
  branchName: string;
  customerName: string;
  customerCode: string;
  customerLocation: string;
  unitCode: string;
  equipment: string;
  merk: string;
  type: string;
  serialNumber: string;
  note: string;
  status: string;
  icNumber: string;
  rentPeriod: string;
};

@Component
export default class UnitExchange extends Vue {
  @Prop({ type: Array as () => IReportDailyOperationalExchange[], default: [] })
  readonly dataSourceUnitExchange!: IReportDailyOperationalExchange[];

  @Prop({ default: false, type: Boolean }) readonly loading!: boolean;

  currentPage = 1;
  pageSize = 10;

  unitExchangeColumns = [
    {
      title: "No.",
      dataIndex: "no",
      width: "75px",
      customRender: (text: number, row: Row, index: number) => {
        const obj = {
          children: text,
          attrs: {} as { rowSpan: number; colSpan: number },
        };
        if (index % 2 === 0) {
          obj.attrs.rowSpan = 2;
        } else if (index % 2 === 1) {
          obj.attrs.rowSpan = 0;
        }
        return obj;
      },
      fixed: "left",
      align: "center",
    },
    {
      title: this.$t("lbl_bast"),
      dataIndex: "bastNo",
    },
    {
      title: this.$t("lbl_delivery_order_number"),
      dataIndex: "documentNumber",
    },
    {
      title: this.$t("lbl_ic_number"),
      dataIndex: "icNumber",
    },
    {
      title: this.$t("lbl_rent_period"),
      dataIndex: "rentPeriod",
    },
    {
      title: this.$t("lbl_branch_name"),
      dataIndex: "branchName",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_customer_code"),
      dataIndex: "customerCode",
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      fixed: "right",
      align: "center",
    },
  ];

  get dataSource(): Row[] {
    const entries: Row[] = [];
    this.dataSourceUnitExchange?.forEach((item, i) => {
      entries.push(this.buildOldRow(item, i));
      entries.push(this.buildNewRow(item, i));
    });
    return entries;
  }

  get pagination() {
    return {
      showTotal: (total): string =>
        this.$t("lbl_total_items", {
          total,
        }).toString(),
      current: this.currentPage,
      showSizeChanger: true,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      defaultPageSize: this.pageSize,
    };
  }

  buildOldRow(data: IReportDailyOperationalExchange, index: number): Row {
    return {
      no: (index + 1).toString(),
      status: "OLD",
      key: generateUUID(), // fake uuid to distinct row
      bastNo: data.bastNumberOld,
      documentNumber: data.doNumberOld,
      branchName: data.branchName,
      customerName: data.customerName,
      customerCode: data.customerCode,
      customerLocation: data.customerLocation,
      unitCode: data.unitCodeOld,
      equipment: data.equipmentOld,
      merk: data.merkOld,
      type: data.typeOld,
      serialNumber: data.serialNumberOld,
      note: data.notesOld,
      icNumber: data.icNumber,
      rentPeriod: data.rentPeriod,
    };
  }

  buildNewRow(data: IReportDailyOperationalExchange, index: number): Row {
    return {
      no: (index + 1).toString(),
      status: "NEW",
      key: generateUUID(), // fake uuid to distinct row
      bastNo: data.bastNumberNew,
      documentNumber: data.doNumberNew,
      branchName: data.branchName,
      customerName: data.customerName,
      customerCode: data.customerCode,
      customerLocation: data.customerLocation,
      unitCode: data.unitCodeNew,
      equipment: data.equipmentNew,
      merk: data.merkNew,
      type: data.typeNew,
      serialNumber: data.serialNumberNew,
      note: data.notesNew,
      icNumber: data.icNumber,
      rentPeriod: data.rentPeriod,
    };
  }

  handleTableChange(pagination, _filters, _sorter): void {
    const { current, pageSize } = pagination;
    this.currentPage = current;

    if (pageSize !== this.pageSize) {
      this.currentPage = FIRST_PAGE;
    }

    this.pageSize = pageSize;
  }
}
